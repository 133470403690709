<template>
  <div
    v-if="moduleStore && moduleStore.mode === 'lms'"
    class="jwl-language__container"
    @click.exact="toggleLanguageMenu">
    <jwl-icon icon-name="angle-down" icon-style="solid" class="jwl-language__language-toggle" />
    {{ $t(`languages.${$route.params.lang}`) }}

    <transition name="flyout">
      <ul class="jwl-language__languages" v-if="languageOpen">
        <li class="jwl-language__language" v-for="language in languages" :key="language">
          <router-link
            class="jwl-language__language-link"
            :to="{ name: route.name, params: { lang: language } }"
            @click="toggleLanguageMenu">
            {{ $t(`languages.${language}`) }}
          </router-link>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import JwlIcon from '@base/components/JwlIcon.vue';
import { useModuleStore } from '@base/store/moduleStore';

export default defineComponent({
  components: {
    JwlIcon,
  },
  setup () {
    const route = useRoute();
    const moduleStore = useModuleStore();
    const languageOpen = ref(false);
    const languages = ['en', 'es', 'ar'];

    const toggleLanguageMenu = () => {
      languageOpen.value = !languageOpen.value;
    };

    return {
      moduleStore,
      route,
      languages,
      languageOpen,
      toggleLanguageMenu,
    };
  },
});
</script>

<style lang="scss">
@use "@base/styles/mixins";
@use "@base/styles/variables";

.jwl-language {
  $root: &;

  &__container {
    align-items: center;
    background-color: var(--color-gray-300);
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: .25rem .75rem;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-top: 1rem;

    @include mixins.ltrtl(margin-right, margin-left, 1.5rem);

    @include mixins.mq(variables.$mq-phone) {
      margin-bottom: 1rem;
      order: 1;
      z-index: 50;

      @include mixins.ltrtl(margin-right, margin-left, 1rem);
    }
  }

  &__language-toggle {
    @include mixins.ltrtl(margin-right, margin-left, .5rem);
  }

  &__languages {
    background-color: var(--color-gray-300);
    border-radius: 3px;
    list-style: none;
    opacity: 1;
    padding: .25rem .5rem;
    position: absolute;
    top: 1.25rem;
    transform: translateY(0);
    transition: opacity .25s, transform .25s;
    min-width: calc(100% - 2.4rem);

    &.flyout-enter,
    &.flyout-leave-to {
      opacity: 0;
      transform: translateY(-.25rem);
    }

    @include mixins.ltr {
      left: 0;
      padding-left: 1.9rem;
    }

    @include mixins.rtl {
      padding-right: 1.75rem;
      right: 0;
    }
  }

  &__language-link {
    color: var(--color-gray-800);
    text-decoration: none;
    transition: color .25s;

    &.router-link-active {
      text-decoration: underline;
    }

    &:hover {
      color: var(--color-blue);
      text-decoration: none;
    }
  }
}

.jwl-header__header {
  .jwl-language__container {
    margin-left: 0;
    margin-top: 0;
  }
}

</style>
