<template>
  <div class="jwl-profile" v-if="hasUserData">
    <div class="jwl-profile__menu-bar" @click="toggle">
      <JwlIcon icon-name="angle-down" icon-style="solid" class="jwl-profile__user-toggle" />
      <span class="jwl-profile__user-name" v-text="currentUser.firstName" />
      <JwlProfileImage class="jwl-profile__user-icon max-h-30px" :image-url="currentUser.profilePhoto" />
    </div>

    <div class="jwl-profile__flyout" :class="flyoutOpenClass">
      <JwlProfileImage class="jwl-profile__flyout-user-icon" :image-url="currentUser.profilePhoto" />
      <div class="jwl-profile__flyout-user-name">
        {{currentUser.firstName}} {{currentUser.lastName}}
      </div>

      <div class="jwl-profile__flyout-user-meta">
        ID: {{currentUser.id}}
      </div>

      <div class="jwl-profile__flyout-action">
        <div class="jwl-profile__flyout-log-out" @click="viewProfile">
          {{$t('common.toProfile')}}
        </div>

        <div class="jwl-profile__flyout-log-out" @click="logout">
          {{$t('general.logOut')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  const router = useRouter();
  const moduleStore = useModuleStore();
  const { currentUser, hasUserData } = useModuleStore();

  const open = ref(false);

  const toggle = () => {
    open.value = !open.value;
  };

  const logout = () => {
    moduleStore.logOutUser();
    router.push({ name: 'login' });
  };

  const viewProfile = () => {
    router.push({ name: 'profile' });
  };

  const flyoutOpenClass = computed(() => (open.value ? 'jwl-profile__flyout--open' : ''));
</script>

<style lang="scss">
@import "../styles/jwlProfile";
</style>
