<template>
  <div class="jwl-header" v-if="moduleStore && moduleStore.currentUser">
    <div class="jwl-header__spacer" />
    <header class="jwl-header__header" :class="headerClass">
      <div class="jwl-header__user-bar">
        <div class="jwl-header__mobile-logo-container">
          <router-link class="w-full" :to="{ name: 'home' }">
            <jwl-svg icon-name="jwl_logo_horizontal" class="no-scale-svg" />
          </router-link>
          <div class="jwl-header__mobile-menu-toggle" @click="toggleOpen" :class="toggleContainerClass">
            <div class="jwl-header__mobile-toggle" :class="toggleOpenClass" />
            <JwlProfileImage class="jwl-header__user-icon mx-h-30px" :image-url="moduleStore.currentUser.profilePhoto" />
          </div>
        </div>
        <div class="jwl-header__user-container container" :class="mobileOpenClass">
          <div class="jwl-header__impersonate-container" v-if="moduleStore.currentUser.canImpersonate || moduleStore.currentUser.impersonated">
            <JwlImpersonate />
          </div>
          <jwl-language-switcher />

          <jwl-profile class="jwl-header__user" />

          <div style="padding-left: 3px">
            {{ moduleStore.currentUser.applicationRoles.join(', ') }}
          </div>
        </div>
      </div>

      <div class="jwl-header__logo-container container">
        <router-link class="jwl-header__logo" :to="{ name: 'home' }">
          <jwl-svg icon-name="jwl_logo_horizontal" class="no-scale-svg" />
        </router-link>
      </div>

      <nav class="jwl-header__nav-container">
        <ul class="jwl-header__nav-wrapper container align-center">
          <router-link class="jwl-header__logo-small" :to="{ name: 'home' }">
            <jwl-svg icon-name="jwl_sun" class="no-scale-svg"/>
          </router-link>

          <li
            v-for="menuNode in moduleStore.menuNodes"
            :key="menuNode"
            class="jwl-header__nav-elem"
            :class="menuNodeClass(menuNode)">
            <div class="jwl-header__nav-link jwl-header__nav-link--with-submenu"
                 v-if="menuNode === 'course' || menuNode === 'class'"
                 :class="activeMenuClass(menuNode)">
              <div class="jwl-header__nav-flyout-label"
                   tabindex="0"
                   @click="toggleOverlay(menuNode)"
                   @keyup.enter="toggleOverlay(menuNode)">
                {{ $t(`menu.${menuNode}`) }}
              </div>

              <div class="jwl-header__submenu" :id="`menu-flyout-${menuNode}`">
                <jwl-menu-overlay class="container"
                  v-if="menuNode === otherOpen"
                  @close-overlay="closeOverlay"
                  :base-menu-name="menuNode === 'course' ? 'concentration_home' : menuNode" />
              </div>

            </div>

            <router-link
              class="jwl-header__nav-link"
              :to="{ name: menuNode, params: { lang: route?.params.lang } }" v-else>
              {{ $t(`menu.${menuNode}`) }}
            </router-link>
          </li>

          <li class="jwl-header__nav-elem" v-if="hasError">
            <router-link
              class="jwl-header__nav-link"
              :to="{ name: 'support_issue', params: { lang: route?.params.lang}, query: { issueType: 'system-error' } }">
              {{ $t(`menu.report-error`) }}
            </router-link>
          </li>

          <li class="jwl-header__small-environment-hint" v-if="environmentName !== 'null'">
            {{ environmentName }}
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  watch,
  onMounted,
  ref,
  nextTick,
} from 'vue';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import jwlProfile from '@base/components/jwlProfile.vue';
import JwlProfileImage from '@base/components/JwlProfileImage.vue';
import JwlImpersonate from '@base/components/JwlImpersonate.vue';
import jwlMenuOverlay from '@base/components/jwlMenuOverlay.vue';
import JwlSvg from '@base/components/JwlSvg.vue';
import { useModuleStore } from '@base/store/moduleStore';
import JwlLanguageSwitcher from '@base/components/JwlLanguageSwitcher.vue';

export const SCROLLED_OFFSET = 250;
export const SCROLLED_BACK_ENABLED_OFFSET = 150;

const SCROLL_STATE_DOWN = 'scroll-down';
const SCROLL_STATE_UP = 'scroll-up';

export default defineComponent({
  components: {
    jwlProfile,
    JwlProfileImage,
    JwlImpersonate,
    jwlMenuOverlay,
    JwlSvg,
    JwlLanguageSwitcher,
  },
  props: {
    withLanguageSwitcher: {
      default: true,
      required: false,
      type: Boolean,
    },
  },
  setup () {
    const router = useRouter();
    const route = useRoute();
    const moduleStore = useModuleStore();
    const mobileOpen = ref(false);
    const languageOpen = ref(false);
    const otherOpen = ref('');
    const environmentName = import.meta.env.VITE_VUE_APP_ENVIRONMENT_NAME;
    const scrollPos = ref(window.scrollY);
    const scrollState = ref(SCROLL_STATE_DOWN);
    const headerVisible = ref(true);
    const headerClass = ref('');
    const languages = ['en', 'es', 'ar'];

    const mobileOpenClass = computed(() => (mobileOpen.value ? 'jwl-header__user-container--open' : ''));
    const toggleContainerClass = computed(() => (mobileOpen.value ? 'jwl-header__mobile-menu-toggle--open' : ''));
    const toggleOpenClass = computed(() => (mobileOpen.value ? 'jwl-header__mobile-toggle--open' : ''));
    const hasError = computed(() => moduleStore.errors.length > 0);

    const toggleOpen = () => {
      mobileOpen.value = !mobileOpen.value;
    };

    const openLanguages = () => {
      languageOpen.value = !languageOpen.value;
    };

    const closeLanguages = () => {
      nextTick(() => {
        if (languageOpen.value) {
          languageOpen.value = false;
        }
      });
    };

    const menuNodeClass = (modifier) => {
      const classes = [`jwl-header__nav-elem--${modifier}`];

      if (modifier === otherOpen.value) {
        classes.push('jwl-header__nav-elem--open');
      }

      return classes.join(' ');
    };

    const toggleOverlay = (nodeName) => {
      if (nodeName === otherOpen.value) {
        const scrollTarget = document.getElementById(`menu-flyout-${nodeName}`);
        enablePageScroll(scrollTarget);
        otherOpen.value = '';
      } else {
        if (moduleStore.baseConcentrations
          && moduleStore.baseConcentrations.dataAsFlatArray().length === 1
          && nodeName === 'course'
          && moduleStore.baseConcentrations.orientation.length <= 0) {
          const concentration = moduleStore.baseConcentrations.dataAsFlatArray()[0];
          router.push({
            name: nodeName === 'course' ? 'concentration_home' : nodeName,
            params: {
              concentration: concentration.code,
            },
          });
        }

        const hasOther = otherOpen.value;

        otherOpen.value = nodeName;
        scrollState.value = SCROLL_STATE_UP;
        if (hasOther) {
          const oldScroll = document.getElementById(`menu-flyout-${hasOther}`);
          enablePageScroll(oldScroll);
        }
        const scrollTarget = document.getElementById(`menu-flyout-${nodeName}`);
        disablePageScroll(scrollTarget);
      }
    };

    const closeOverlay = () => {
      enablePageScroll();
      otherOpen.value = '';
    };

    const handleScroll = () => {
      let scrolledBackTimer = null;
      const oldScrollPos = scrollPos.value;
      const newScrollPos = window.scrollY;

      if (scrollState.value !== SCROLL_STATE_DOWN && newScrollPos > SCROLLED_OFFSET && oldScrollPos <= SCROLLED_OFFSET) {
        scrollState.value = SCROLL_STATE_DOWN;
      } else if (scrollState.value === SCROLL_STATE_DOWN && newScrollPos < SCROLLED_OFFSET) {
        scrollState.value = SCROLL_STATE_UP;

        if (scrolledBackTimer) {
          clearTimeout(scrolledBackTimer);
          scrolledBackTimer = null;
        }
      }

      if (scrollState.value === SCROLL_STATE_UP && newScrollPos > oldScrollPos) {
        // scrolled forward
        scrollState.value = SCROLL_STATE_DOWN;
        if (scrolledBackTimer) {
          clearTimeout(scrolledBackTimer);
          scrolledBackTimer = null;
        }
      } else if (scrollState.value === SCROLL_STATE_DOWN && newScrollPos < oldScrollPos && newScrollPos > SCROLLED_BACK_ENABLED_OFFSET) {
        // scrolled back
        if (!scrolledBackTimer) {
          scrolledBackTimer = setTimeout(() => {
            scrollState.value = SCROLL_STATE_UP;
            scrolledBackTimer = null;
          }, 100);
        }
      }
      // remember current scroll pos
      scrollPos.value = newScrollPos;
      headerVisible.value = scrollState.value === SCROLL_STATE_UP;
      if (!headerVisible.value) {
        headerClass.value = 'jwl-header__header--hidden';
        document.body.classList.add('jwl-menu-hidden');
      } else {
        headerClass.value = '';
        document.body.classList.remove('jwl-menu-hidden');
      }
    };

    const activeMenuClass = (menuNode) => {
      const activeRoute = route.name;
      if (menuNode === 'course') {
        const containingPaths = [
          'course',
          'concentration_home',
          'concentration_itinerary',
          'unit',
          'unit_overview',
          'unit_wbt',
          'submit_text',
          'submit_work',
          'discuss',
          'track',
        ];
        return containingPaths.includes(activeRoute)
          ? 'jwl-header__nav-link--active'
          : '';
      }
      if (menuNode === 'class') {
        const containingPaths = ['class_root', 'class', 'grade', 'grade_discussion'];
        return containingPaths.includes(activeRoute)
          ? 'jwl-header__nav-link--active'
          : '';
      }
      return '';
    };

    const routeChange = () => {
      nextTick(() => {
        closeOverlay();
        closeLanguages();
        mobileOpen.value = false;
      });
    };

    watch(route, routeChange);

    onMounted(() => {
      handleScroll();
      window.addEventListener('scroll', handleScroll, { passive: true });
    });

    return {
      environmentName,
      languageOpen,
      headerVisible,
      headerClass,
      mobileOpen,
      mobileOpenClass,
      toggleContainerClass,
      toggleOpenClass,
      hasError,
      languages,
      toggleOpen,
      openLanguages,
      closeLanguages,
      menuNodeClass,
      toggleOverlay,
      closeOverlay,
      handleScroll,
      activeMenuClass,
      moduleStore,
      route,
      otherOpen,
    };
  },
});
</script>

<style lang="scss">
@use "@base/styles/mixins";
@use "@base/styles/variables";

.jwl-header {
  $root: &;

  --user-header-height: 4.5rem;

  &__spacer {
    height: var(--menu-height);
    width: 100%;
  }

  &__header {
    background-color: var(--color-white);
    box-shadow: var(--color-gray-300) 0 .125em .125em;
    height: var(--menu-height);
    left: 0;
    margin-bottom: 1rem;
    position: fixed;
    top: 0;
    transition: top .25s;
    width: 100%;
    z-index: 1000;

    &--hidden {
      top: calc((var(--menu-height) - var(--nav-bar-height)) * -1);

      #{$root}__logo-small {
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0);
      }
    }
  }

  &__user-bar {
    background-color: var(--color-gray-100);
    height: 1.75rem;
    margin-bottom: .5rem;
    padding: .25rem 1rem;
    position: relative;

    @include mixins.mq(variables.$mq-phone) {
      background-color: var(--color-white);
      height: var(--user-header-height);
      margin-bottom: 0;
      padding: 0 0;
    }
  }

  &__mobile-logo-container {
    display: none;

    @include mixins.mq(variables.$mq-phone) {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      height: var(--user-header-height);
      justify-content: space-between;
      width: 100%;

      @include mixins.ltrtl(padding-left, padding-right, 1.5rem);

      svg {
        height: auto;
        max-height: 3rem;
        max-width: 100%;
      }
    }
  }

  &__mobile-menu-toggle {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    padding: 0 1.5rem 0 .75rem;
    transition: background-color .25s;

    &--open {
      background-color: var(--color-gray-100);
    }
  }

  &__mobile-toggle {
    align-items: center;
    align-self: center;
    color: var(--color-grey);
    cursor: pointer;
    display: flex;
    grid-area: toggle;
    height: .6rem;
    justify-content: center;
    justify-self: center;
    margin-right: .5rem;
    position: relative;
    transition: height .2s;
    width: .95rem;

    &::before,
    &::after {
      background-color: currentColor;
      border-radius: .1rem;
      content: '';
      display: block;
      height: .2rem;
      position: absolute;
      transition: transform .2s, color .2s;
      width: .62rem;
    }

    &::before {
      left: 0;
      transform: rotate(45deg);
      transform-origin: center center;
    }

    &::after {
      right: 0;
      transform: rotate(-45deg);
      transform-origin: center center;
    }

    &--open {
      color: var(--menu-color);

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &__user-icon {
    font-size: 1.25rem;
    height: 2rem;
    width: 2rem;
  }

  &__user-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: var(--container-width);
    width: 100%;

    @include mixins.mq(variables.$mq-phone) {
      background-color: var(--color-gray-100);
      box-sizing: border-box;
      display: flex;
      flex-flow: row wrap;
      height: 0;
      justify-content: space-between;
      left: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0 0;
      pointer-events: none;
      position: absolute;
      top: 100%;
      transform: translateY(-.25rem);
      transition: opacity .25s, transform .25s;
      width: 100vw !important;
      z-index: 100;

      &--open {
        height: auto;
        opacity: 1;
        padding: 1rem 1.5rem;
        pointer-events: auto;
        transform: translateY(0);
      }
    }
  }

  &__impersonate-container {
    display: var(--toggle-state);

    @include mixins.mq(variables.$mq-phone) {
      margin-bottom: 1rem;
      order: 2;

      .jwl-impersonate {
        @include mixins.ltrtl(margin-right, margin-left, 0);
      }
    }
  }

  &__roles-container {
    align-items: center;
    background-color: var(--color-gray-300);
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: .25rem .75rem;
    position: relative;

    @include mixins.ltrtl(margin-right, margin-left, 1.5rem);

    @include mixins.mq(variables.$mq-phone) {
      margin-bottom: 1rem;
      order: 1;
      z-index: 50;

      @include mixins.ltrtl(margin-right, margin-left, 1rem);
    }
  }

  &__roles-toggle {
    @include mixins.ltrtl(margin-right, margin-left, .5rem);
  }

  &__roles {
    background-color: var(--color-gray-300);
    border-radius: 3px;
    list-style: none;
    opacity: 1;
    padding: .25rem .5rem;
    position: absolute;
    top: 1.25rem;
    transform: translateY(0);
    transition: opacity .25s, transform .25s;
    min-width: calc(100% - 2.4rem);

    &.flyout-enter,
    &.flyout-leave-to {
      opacity: 0;
      transform: translateY(-.25rem);
    }

    @include mixins.ltr {
      left: 0;
      padding-left: 1.9rem;
    }

    @include mixins.rtl {
      padding-right: 1.75rem;
      right: 0;
    }
  }

  &__roles-link {
    color: var(--color-gray-800);
    text-decoration: none;
    transition: color .25s;

    &.router-link-active {
      text-decoration: underline;
    }

    &:hover {
      color: var(--color-blue);
      text-decoration: none;
    }
  }

  &__user {
    display: flex;

    @include mixins.mq(variables.$mq-phone) {
      order: 3;
      width: 100%;
    }
  }

  &__logo-container {
    display: flex;
    flex-direction: row;
    margin-bottom: .5rem;

    @include mixins.mq(variables.$mq-phone) {
      display: none;
    }

    svg {
      height: 6rem;
    }
  }

  &__small-environment-hint {
    background-color: var(--color-error);
    border-radius: var(--border-radius);
    color: var(--color-white);
    padding: .25rem .5rem;
    text-transform: uppercase;
  }

  &__logo-small {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translateY(-1rem);
    transition: opacity var(--transition-duration), transform var(--transition-duration);

    svg {
      height: 1.75rem;
      width: 1.75rem;
    }
  }

  &__nav-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: var(--nav-bar-height);
    justify-content: flex-start;
    max-width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    @include mixins.mq(variables.$mq-phone) {
      background-color: var(--color-gray-100);
    }
  }

  &__nav-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;
    margin: 0 0;
    padding: 0 0;
    position: relative;
  }

  &__nav-elem {
    @include mixins.propertyColor('color', '.router-link-active');
    --link-color: var(--color-gray-700);

    list-style: none;
    margin-right: 1rem;
    padding: .25em 0;

    .router-link-active,
    & #{$root}__nav-link--active {
      color: var(--color-blue);
      font-weight: var(--font-weight-normal);
    }

    &--open {
      --link-color: var(--color-blue);

      &::after {
        border-bottom-color: var(--color-blue);
      }

      #{$root}__nav-flyout-label {
        position: relative;

        &::after {
          border-bottom: .5rem solid var(--link-color);
          border-left: .5rem solid transparent;
          border-right: .5rem solid transparent;
          bottom: -.5rem;
          content: "";
          height: 0;
          left: calc(50% - .25rem);
          position: absolute;
          width: 0;

          @include mixins.mq(variables.$mq-phone) {
            bottom: -.75rem;
          }
        }
      }

      #{$root}__submenu {
        height: calc(100% - var(--menu-height));
        opacity: 1;
        overflow: auto;
        pointer-events: all;
        transition-delay: .2s;
      }
    }

    &:not(:last-child) #{$root}__nav-link {
      @include mixins.ltr {
        border-right: 1px solid var(--color-gray-300);
        padding-right: 1rem;
      }

      @include mixins.rtl {
        border-left: 1px solid var(--color-gray-300);
        padding-left: 1rem;
      }
    }

    &--home,
    &--clcPartner {
      & .router-link-active,
      &:hover #{$root}__nav-link {
        color: var(--color-blue);
      }
    }

    &--student .router-link-active,
    &--student:hover #{$root}__nav-link {
      color: var(--color-orange);
    }

    &--facilitator .router-link-active,
    &--facilitator:hover #{$root}__nav-link {
      color: var(--color-cyan);
    }

    &--coordinator .router-link-active,
    &--coordinator:hover #{$root}__nav-link {
      color: var(--color-green);
    }

    &--programme .router-link-active,
    &--programme:hover #{$root}__nav-link {
      color: var(--color-success);
    }

    &--user .router-link-active,
    &--user:hover #{$root}__nav-link {
      color: var(--color-indigo);
    }

    &--reporting .router-link-active,
    &--reporting:hover #{$root}__nav-link {
      color: var(--color-purple);
    }
  }

  &__nav-link {
    color: var(--link-color);
    text-decoration: none;
    transition: color var(--transition-time);

    &:hover {
      color: var(--color-blue);
      text-decoration: none;
    }

    &.router-link-active {
      font-weight: var(--font-weight-normal);
    }

    &--with-submenu {
      cursor: pointer;
      position: relative;

      &::after {
        border-bottom: .3rem solid transparent;
        border-left: .3rem solid transparent;
        border-right: .3rem solid transparent;
        bottom: -.3rem;
        content: "";
        position: absolute;
        left: calc(50% - .6rem);
      }
    }
  }

  &__submenu {
    backdrop-filter: blur(3px);
    background-color: transparentize(variables.$white, .1);
    border-top: .2rem solid var(--color-blue);
    cursor: default;
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: var(--menu-height);
    transition: opacity .2s, height .2s;
    transition-delay: .2s;
    width: 100%;
  }
}
</style>
