<template>
  <component
    :key="`${iconName}`"
    :is="svg"
    alt="test" />
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: false,
    },
  },
  setup (props) {
    const { iconName } = props;
    const svg = defineAsyncComponent(() => import(`../assets/${iconName}.svg?component`));

    return {
      svg,
    };
  },
});
</script>

<style lang="scss">
.jwl-icon {
  &--margin-right {
    margin-right: .5rem;
  }
}
</style>
