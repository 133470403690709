<template>
  <div class="jwl-profile-image">
    <img class="jwl-profile-image__image" ref="profileImage" width="75" height="75" v-show="!showFallback" />
    <div class="jwl-profile-image__fallback" v-if="showFallback">
      <JwlIcon icon-name="user" icon-style="duotone" />
    </div>
  </div>
</template>

<script>
import { api } from '@base/store/moduleStore';
import JwlIcon from '@base/components/JwlIcon.vue';

export default {
  name: 'JwlProfileImage',
  components: {
    JwlIcon,
  },
  props: {
    imageUrl: String,
    imageBlob: null,
  },
  data () {
    return {
      showFallback: true,
    };
  },
  methods: {
    fetchImage () {
      api.axios.get(this.imageUrl, {
        responseType: 'arraybuffer',
      })
        .then((response) => {
          try {
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            });
            const img = this.$refs.profileImage;
            img.onload = () => {
              URL.revokeObjectURL(img.src);
            };
            img.src = URL.createObjectURL(blob);

            this.showFallback = false;
          } catch (e) {
            this.showFallback = true;
          }
        });
    },
  },
  mounted () {
    if (this.imageUrl) {
      this.fetchImage();
    }
  },
};
</script>

<style lang="scss">
.jwl-profile-image {
  aspect-ratio: 1 / 1;
  background-color: var(--color-gray-400);
  border-radius: 50%;
  display: grid;
  font-size: 3.25rem;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  height: 1.145em;
  overflow: hidden;
  width: 1.145em;

  &__image,
  &__fallback {
    align-items: center;
    display: flex;
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &__fallback {
    color: var(--color-gray-700);
    font-size: 1em;
  }
}
</style>
