<template>
  <div class="jwl-impersonate">
    <v-select
      v-model="user"
      placeholder="Impersonate"
      label="label"
      track-by="id"
      :show-labels="false"
      :multiple="false"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      @search-change="fetchUsers"
      @close="options = []"
      @select="impersonate"
      :options="options">
      <template slot="singleLabel" slot-scope="props">
        <span class="jwl-impersonate__desc">
          <span class="jwl-impersonate__title" v-text="props.option.label" />
        </span>
      </template>

      <template slot="noResult" slot-scope="props">
        {{$root.$t(emptyResultText(props))}}
      </template>

      <template slot="option" slot-scope="props">
        <div class="jwl-impersonate__desc">
          <span class="jwl-impersonate__title" v-text="props.option.label" />
          <span class="jwl-impersonate__small">({{ props.option.jwlId }})</span>
        </div>
      </template>
    </v-select>

    <div class="jwl-impersonate__exit-button" v-if="currentUser && currentUser.impersonated" @click="exitImpersonate">
      <JwlIcon icon-name="portal-exit" icon-style="duotone" />
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapState, mapStores } from 'pinia';
import useModuleStore from '@base/store/moduleStore';
import JwlIcon from '@base/components/JwlIcon.vue';
import { IMPERSONATE_KEY } from '@base/store/JwlApi';

export default {
  name: 'JwlImpersonate',
  components: {
    JwlIcon,
    'v-select': Multiselect,
  },
  data () {
    return {
      user: null,
      options: [],
      isLoading: false,
      error: null,
    };
  },
  methods: {
    fetchUsers (search) {
      if (search.length > 3) {
        this.isLoading = true;
        this.moduleStore.getImpersonators(search)
          .then((resultList) => {
            this.options = resultList;
            this.isLoading = false;
          })
          .catch((e) => {
            this.error = e;
          });
      }
    },
    impersonate (selectedOption) {
      localStorage.setItem(IMPERSONATE_KEY, selectedOption.id);
      document.location.reload();
    },
    exitImpersonate () {
      localStorage.removeItem(IMPERSONATE_KEY);
      document.location.reload();
    },
    emptyResultText (props) {
      return props.search.length < 4 ? 'common.needs4chars' : 'common.noResultsFound';
    },
  },
  computed: {
    ...mapStores(useModuleStore),
    ...mapState(useModuleStore, [
      'currentUser',
    ]),
  },
};
</script>

<style lang="scss">
@use '../styles/variables';
@use '../styles/mixins';
@use '../styles/multiselect';

.jwl-impersonate {
  @include mixins.ltrtl(margin-right, margin-left, 1rem);

  align-items: center;
  background-color: variables.$bg;
  display: flex;
  justify-content: flex-start;

  &__small {
    @include mixins.ltrtl(margin-left, margin-right, .25rem);

    color: var(--color-grey);
    font-size: .8em;
  }

  .multiselect {
    box-sizing: border-box;
    min-height: 1.5rem;

    &__tags {
      border-radius: var(--border-radius);
      padding-bottom: .2rem;
      padding-top: .2rem;

      input {
        padding: 0 0;
      }
    }
  }

  &__exit-button {
    @include mixins.ltrtl(margin-left, margin-right, .5rem);

    align-items: center;
    background-color: var(--color-error);
    box-sizing: border-box;
    border: .1rem solid var(--color-error);
    border-radius: var(--border-radius);
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: .4rem .4rem;
    transition: background-color .25s, color .25s;

    &:hover {
      background-color: var(--color-white);
      color: var(--color-error);
    }
  }
}
</style>
